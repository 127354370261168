<template>
    <div>
        <strong>Conforming Document Upload</strong>
        <br>
        <br>
        <div class="form-group">
            <label>Curb Part #</label>
            <Typeahead cdi_type="curb" v-bind:ID.sync="state.referenceID" />
        </div>
    </div>
</template>

<script>
import { fileUploadStore } from "@/store/FileUpload.store";
import Typeahead from "@/components/utils/typeaheads/Typeahead";
import conforming from '@/cache/conforming_stamp.cache.js';


export default {
    name: "Conforming",
    components: {
        Typeahead
    },
    data() {
        return {
            state: fileUploadStore.state,
            model_id: '',
            cache: {
                conforming
            }
        }
    },
    created() {
        fileUploadStore.initialize(this.$cdiVars.FILE_TYPE_CONFORMING_STAMP);
    },
    mounted() {
        this.state.conformingTypeID = this.$appStore.state.fileUploadContextData.data.conforming_type_id;
    },
}
</script>

<style scoped>

</style>